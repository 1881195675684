<template>
	<div class="page">
		<div class="transaction-detail">
			<div class="transaction-meta">
				<div class="transaction-type">{{ transactionType }}</div>
				<div class="transaction-id"><input type="text" :value="transactionID" /></div>
				<div class="transaction-timestamp">{{ timestampStr }}</div>
				<div class="transaction-notes" v-for="(note, i) in notes" :key="i">{{ note }}</div>
			</div>
			<div class="split">
				<button :class="{ 'selected': mode === 'summary' }" @click="mode = 'summary'">Summary</button>
				<button :class="{ 'selected': mode === 'advanced' }" @click="mode = 'advanced'">Detail</button>
			</div>
			<transaction-raw :transaction="transaction" v-if="mode === 'advanced'" />
			<transaction-summary :transaction="transaction" v-else />
			<div class="fees">
				<div class="fee-address">Transaction Fee</div>
				<div class="text-right">
					<div class="amount-secondary">{{ formatSiacoinString(fees, 'never') }}</div>
					<div class="amount-secondary">{{ formatCurrencyString(fees, 'never') }} <span class="exchange-rate">@ {{ formattedRate }}</span></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { BigNumber } from 'bignumber.js';
import { getFoundationTransaction } from '@/api';
import { formatSiacoinString, formatCurrencyString, formatExchangeRate } from '@/format';

import TransactionSummary from '@/components/TransactionSummary';
import TransactionRaw from '@/components/TransactionRaw';

export default {
	components: {
		TransactionSummary,
		TransactionRaw
	},
	props: {
		transactionID: String
	},
	computed: {
		...mapState(['currencyCode']),
		timestampStr() {
			return this.timestamp.toLocaleString([], {
				year: 'numeric',
				month: 'short',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric'
			});
		},
		formattedRate() {
			const rate = parseFloat(this.transaction?.exchange_rate?.rate || '0'),
				currency = this.transaction?.exchange_rate?.currency || 'usd';

			return formatExchangeRate(rate, currency, 'never');
		}
	},
	data() {
		return {
			mode: 'summary',
			fees: new BigNumber(0),
			participants: {
				'': {
					siacoins: new BigNumber(0),
					siafunds: new BigNumber(0),
					self: false
				}
			},
			transaction: null,
			transactionType: 'Siacoin Transaction',
			notes: [],
			timestamp: new Date()
		};
	},
	async beforeMount() {
		this.transaction = await getFoundationTransaction(this.transactionID, this.currencyCode);
		this.fees = new BigNumber(this.transaction.fees);
		this.timestamp = new Date(this.transaction.timestamp);
		this.transactionType = this.friendlyType(this.transaction.tags[0]);
		this.notes = this.transaction.notes;
	},
	methods: {
		formatSiacoinString(val, sign = 'always') {
			return formatSiacoinString(val, sign);
		},
		formatCurrencyString(val, sign = 'always') {
			const rate = parseFloat(this.transaction?.exchange_rate?.rate || '0'),
				currency = this.transaction?.exchange_rate?.currency || 'usd';
			return formatCurrencyString(val, rate, currency, sign);
		},
		friendlyType(tag) {
			switch (tag) {
			case 'defrag':
				return 'Defrag';
			case 'siafund_transaction':
				return 'Siafund Transaction';
			case 'storage_proof':
				return 'Storage Proof';
			case 'contract_renewal':
				return 'Contract Renewal';
			case 'contract_formation':
				return 'Contract Formation';
			case 'contract_revision':
				return 'Contract Revision';
			case 'host_announcement':
				return 'Host Announcement';
			case 'foundation_subsidy':
				return 'Foundation Subsidy';
			default:
				return 'Transaction';
			}
		}
	}
};
</script>

<style lang="stylus" scoped>
.page {
	display: grid;
	align-content: safe center;
	justify-items: center;
}

.transaction-detail {
	width: 100%;
	padding: 15px;
	max-width: 700px;
}

.split {
	text-align: center;
	margin-bottom: 5px;

	button {
		padding: 4px 8px;
		outline: none;
		background: none;
		border: none;
		border-radius: 16px;
		color: rgba(255, 255, 255, 0.54);
		cursor: pointer;

		&.selected {
			background: primary;
			color: rgba(0, 0, 0, 0.54);
		}
	}
}

.fees {
	display: grid;
	padding: 15px;
	grid-template-columns: repeat(2, auto);
	align-items: center;
	justify-content: space-between;
	color: rgba(255, 255, 255, 0.54);
}

.transaction-meta {
	text-align: center;
	margin-bottom: 15px;

	.transaction-type {
		color: primary;
		font-size: 1.3rem;
		margin-bottom: 5px;
	}

	.transaction-id {
		color: rgba(255, 255, 255, 0.54);
		margin-bottom: 2px;

		input {
			display: block;
			width: 100%;
			background: none;
			outline: none;
			border: none;
			color: inherit;
			font-size: inherit;
			text-align: inherit;
			text-overflow: ellipsis;
		}
	}

	.transaction-notes {
		color: rgba(255, 255, 255, 0.54);
		font-size: 1rem;
		margin-top: 10px;
	}

	.transaction-notes {
		font-style: italic;
	}
}
</style>